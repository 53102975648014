<template>
  <div>
    <b-navbar>
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ path: '/forum' }">
          论坛
        </b-navbar-item>
      </template>
      <template slot="start">
        <b-navbar-item tag="router-link" :to="{ path: '/AllGallery' }">
          画廊
        </b-navbar-item>
        <b-navbar-dropdown label="Info">
          <b-navbar-item href="#"> About </b-navbar-item>
          <b-navbar-item href="#"> Contact </b-navbar-item>
        </b-navbar-dropdown>
      </template>
    </b-navbar>
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">画廊</h1>
          <h2 class="subtitle">色图？</h2>
        </div>
      </div>
    </section>
    <b-button
      size="large"
      icon-left="image"
      type="is-warning"
      :rounded="true"
      tag="router-link"
      :to="{ path: '/UploadGallery' }"
      style="
        position: fixed;
        top: 50px;
        right: 50px;
        z-index: 2000;
        transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -webkit-transform: scale(1.2, 1.2);
      "
      @click="show"
    ></b-button>
    <div class="columns" style="padding: 50px">
      <div v-for="items in gallery" :key="items">
        <div class="column">
          <div class="card">
            <div class="card-image">
              <figure>
                <img
                  v-lazy="items.raw.images[0].compressed"
                  alt="Placeholder image"
                />
              </figure>
            </div>
            <div class="card-content">
              <div class="media">
                <!-- <div class="media-left">
                  <figure class="image is-48x48">
                    <img
                      src="https://bulma.io/images/placeholders/96x96.png"
                      alt="Placeholder image"
                    />
                  </figure>
                </div> -->
                <div class="media-content">
                  <p class="title is-4" @click="view(items.gid)">
                    {{ items.raw.title }}
                  </p>
                  <p class="subtitle is-6">{{ items.raw.userId }}</p>
                </div>
              </div>
                <b-tag type="is-warning" v-for="tag in items.raw.tags" :key="tag">{{ tag.name ? tag.name : tag }}</b-tag>
              
              <div class="content">
                {{ items.raw.text.substr(0, 100) }}
                <br />
                <time datetime="2016-1-1">{{ items.last_update }}</time>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section
      style="
        padding-top: 0px;
        padding-bottom: 1cm;
        padding-left: 50px;
        padding-right: 50px;
      "
    >
      <b-pagination
        :total="total"
        v-model="current"
        :order="order"
        :size="size"
        :simple="isSimple"
        :rounded="isRounded"
        :per-page="perPage"
        :icon-prev="prevIcon"
        :icon-next="nextIcon"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        @change="nextpage"
      >
      </b-pagination>
    </section>
    <b-button
      type="is-warning"
      :rounded="true"
      icon-left="redo"
      @click="refresh"
      style="position: fixed; bottom: 50px; right: 50px; z-index: 2000"
    ></b-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      gallery: [],
      total: 0,
      current: 1,
      perPage: 3,
      rangeBefore: 3,
      rangeAfter: 1,
      order: "",
      size: "",
      postinfo: "",
      isSimple: false,
      isRounded: true,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
    };
  },
  methods: {
    refresh() {
      this.$router.go(0);
    },
    view(id) {
      this.$router.push({
        name: "Gallery",
        query: {
          gid: id,
        },
      });
    },
    show(file) {
      console.log(file);
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    async nextpage(val) {
      let res = await this.$http.get("/api/gallery/p/" + val.toString(), {
        headers: {
          pass: "",
          Authorization: "Bearer " + (await localStorage.getItem("token")),
        },
      });
      this.gallery = res.data.gallery;
    },
  },
  async beforeCreate() {
    this.$buefy.toast.open("出现问题请点击右下角刷新");

    let res = await this.$http.get("/api/gallery/p/1", {
      headers: {
        pass: "",
        Authorization: "Bearer " + (await localStorage.getItem("token")),
      },
    });
    this.gallery = res.data.gallery;
    res = await this.$http.get("/api/gallery/count", {
      headers: {
        pass: "",
        Authorization: "Bearer " + (await localStorage.getItem("token")),
      },
    });
    this.total = res.data;
  },
};
</script>

<style>
</style>